import React, { Fragment } from 'react';

import './App.css';
import Router from './pages/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authActions, store } from './store';

function App() {
  window.addEventListener('storage', (event) => {
    if (event.key === 'persist:root') {
      const persistedState = JSON.parse(event.newValue);
      const authState = JSON.parse(persistedState.auth);
      if (authState.isSignedIn !== store.getState().auth.isSignedIn) {
        let country = localStorage.getItem("country_slug")
        sessionStorage.setItem("country_id", country);
        store.dispatch(authActions.setIsSignedIn(authState.isSignedIn));
        store.dispatch(authActions.setCountryData(authState.countryData));
        store.dispatch(authActions.setRole(authState));
      }
    }
  });

  return (
    <Fragment>
      <ToastContainer limit={1} />
      <Router />
    </Fragment>
  )
}

export default App;